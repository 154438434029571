
const _baseCdnUrl = "https://d3vdd33llamc8d.cloudfront.net/landing-page"

const assetIcons = {
  arrowRight: _baseCdnUrl + "/svgs/arrow-right.svg",
  check: _baseCdnUrl + "/svgs/check.svg",
  chevDown: _baseCdnUrl + "/svgs/chev-down.svg",
  clock: _baseCdnUrl + "/svgs/clock.svg",
  email: _baseCdnUrl + "/svgs/email.svg",
  facebook: _baseCdnUrl + "/svgs/facebook.svg",
  instagram: _baseCdnUrl + "/svgs/instagram.svg",
  linkedin: _baseCdnUrl + "/svgs/linkedin.svg",
  play: _baseCdnUrl + "/svgs/play.svg",
  twitter: _baseCdnUrl + "/svgs/twitter.svg",
  users: _baseCdnUrl + "/svgs/users.svg",
  video: _baseCdnUrl + "/svgs/video.svg",
  close: _baseCdnUrl + "/svgs/close.svg",
  menuBar: _baseCdnUrl + "/svgs/menu.svg",
  chevLeft: _baseCdnUrl + "/svgs/chev-left.svg",
  chevRight: _baseCdnUrl + "/svgs/chev-right.svg",
  // Add more as needed
};

const assetImages = {
  logo: _baseCdnUrl + "/pngs/logo.png",
  heroImage:  _baseCdnUrl + "/pngs/large-hero.png",
  herosmallImage:
    _baseCdnUrl +  "/pngs/small-hero.png",
  discover:  _baseCdnUrl + "/pngs/discover.png",
  tailored:  _baseCdnUrl + "/pngs/tailored.png",
  noLimits:  _baseCdnUrl + "/pngs/no-limits.png",
  free1:  _baseCdnUrl + "/pngs/free1.png",
  free2:  _baseCdnUrl + "/pngs/free2.png",
  free3:  _baseCdnUrl + "/pngs/free3.png",
  video:  _baseCdnUrl + "/pngs/video.png",
  yogaBall:  _baseCdnUrl + "/pngs/yoga-ball.png",
  yogaMat:  _baseCdnUrl + "/pngs/yoga-mat.png",
  // Add more as needed
};

const assetVideos = {
  heroVideo: "https://d1uxhsybh3036x.cloudfront.net/saas/vids/hero-video-2.mp4",
  // Add more as needed
};

export { assetIcons, assetImages, assetVideos };
